@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.swal2-container {
  z-index: 99999 !important;
}

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #00000022;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

*::-webkit-scrollbar-thumb {
  background-color: #6D5EF7;
  outline: 1px solid #ffffff44;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1A0B3F;
  min-height: 100vh;
  overflow-x: hidden;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.swal2-styled.swal2-confirm {
  background-color: #1A0B3F !important;
  border: none;
  color: white;
}

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #00000022;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

*::-webkit-scrollbar-thumb {
  background-color: #F8C600;
  outline: 3px solid #ffffff44;
}

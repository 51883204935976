@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Titan+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Titan+One&display=swap');

.container-header {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    min-height: 107px;
    background: transparent;
    position: relative;
}

.logo-header {
    width: 123px;
    height: auto;
    position: absolute;
    top: 50%;
}

.MuiGrid-root.header {
    display: flex !important;
    justify-content: space-between;
    position: relative !important;
    z-index: 9;
}

.MuiGrid-item.header {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
}

.MuiGrid-root.header.mobile {
    display: none !important;
}

.MuiGrid-item.header.mobile {
    display: none !important;
}

.header-back-1 {
    height: 50%;
    width: 100%;
    background: #EAEBF3;
    position: absolute;
    top: 0;
    z-index: 0;
}

.header-back-2 {
    height: 50%;
    width: 100%;
    background: #EAEBF3;
    position: absolute;
    top: 30%;
    z-index: 0;
    padding-bottom: 10px;
}

.button-mint {
    background: #6D5EF7;
    color: white;
    border: none !important;
    box-shadow: 0 0 0 0 !important;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-top: -15px !important;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-mint:hover {
    background-color: #1A0B3F;
    background-size: 100% 100%;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.button-header {
    color: #7169BF !important;
    border: none !important;
    height: 40px;
    width: 100%;
    letter-spacing: 0.1rem !important;
    font-weight: bold;
    border-radius: 10px !important;
    background: #c7c8cf55 !important;
    box-shadow: 14px -14px 29px #c7c8cf,
    -14px 14px 29px #ffffff !important;
}

.button-header:hover {
    color: #F0B12B !important;
}

.menu-container {
    background-color: #1A0B3F;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    position: absolute;
    width: 200px;
    height: 200px;
    right: -80px;
    top: -80px;
    transition: all 0.3s;
    z-index: 10;
    box-shadow: 4px -4px 2px #c7c8cf,
    -4px 4px 20px #ffffff !important;
}

.menu-container.full-menu {
    border-radius: 0;
    padding: 0 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh !important;
    left: 0;
    top: 0;
    transition: all 0.3s;
    z-index: 10;
}

.full-menu .menu {
    top: 40px;
    right: 40px;
}

.menu {
    color: white;
    font-size: 3.5em !important;
    position: absolute !important;
    top: 15px;
    right: 10px;
    z-index: 12 !important;
    transition: all 0.3s;
}

.menu i {
    opacity: 0.7;
    transform: scale(1);
    transition: all 0.3s;
}

.menu i:hover {
    opacity: 1;
    transform: scale(1.2);
    transition: all 0.3s;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 11 !important;
}

.overlay.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;
}

.overlay.open ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.overlay.open li {
    animation: fadeInRight 0.5s ease forwards;
    animation-delay: 0.35s;
    height: auto;
    width: auto;
    padding: 10px !important;
}

.overlay.open li:nth-of-type(2) {
    animation-delay: 0.4s;
}

.overlay.open li:nth-of-type(3) {
    animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(4) {
    animation-delay: 0.5s;
}

.overlay nav {
    font-size: 3.2em;
    font-family: "Titan One", san-serif;
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    text-align: center;
}

.overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
}

.overlay ul li {
    display: block;
    height: 25%;
    height: calc(100% / 4);
    min-height: 50px;
    position: relative;
    opacity: 0;
}

.overlay ul li a {
    display: block;
    position: relative;
    color: #fff;
    text-decoration: none;
    overflow: hidden;
    opacity: 0.7;
    transform: scale(1);
    transition: all 0.3s;
}

.overlay ul li a:hover, .overlay ul li a:focus, .overlay ul li a:active {
    opacity: 1;
    transform: scale(1.2);
    transition: all 0.3s;
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

@media screen and (max-width: 899px) {
    .MuiGrid-root.header {
        display: none !important;
    }

    .MuiGrid-root.header.mobile {
        display: flex !important;
        justify-content: space-between;
        position: relative !important;
    }

    .MuiGrid-item.header.mobile {
        display: flex !important;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}

@media screen and (min-width: 900px) {
    .menu-container {
        display: none !important;
    }

    .menu {
        display: none !important;
    }

    .overlay {
        display: none !important;
    }
}
